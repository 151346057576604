import React from 'react'
import danielruizImg from '../../assets/images/welcome_profile.png'

const Welcome = () => {
  return (
    <section className='home section' id='home'>
      <div className='home__container container grid'>
        <div className='home__content grid'>
          <div className='home__social'>
            <a
              href='https://www.linkedin.com/in/danirupe/'
              rel='noreferrer'
              target='_blank'
              className='home__social-icon'
            >
              <i className='uil uil-linkedin-alt'></i>
            </a>
            <a
              href='https://github.com/danirupe'
              rel='noreferrer'
              target='_blank'
              className='home__social-icon'
            >
              <i className='uil uil-github-alt'></i>
            </a>
            <a
              href='https://www.instagram.com/daniruizp95/'
              rel='noreferrer'
              target='_blank'
              className='home__social-icon'
            >
              <i className='uil uil-instagram'></i>
            </a>
          </div>

          <div className='home__img'>
            <img
              className='home__blob-img'
              src={danielruizImg}
              alt='Daniel Ruiz'
            />
          </div>

          <div className='home__data'>
            <h1 className='home__tile'>Hey, I'm Daniel Ruiz 👋🏼</h1>
            <h3 className='home__subtitle'>Front-end developer</h3>
            <p className='home__description'>
              Specialised in developing interactive and user friendly interfaces.
            </p>
            <div className='about__info'>
              <div>
                <span className='about__info-title'>04+</span>
                <span className='about__info-name'>
                  Years <br /> experience
                </span>
              </div>

              <div>
                <span className='about__info-title'>08+</span>
                <span className='about__info-name'>
                  Completed <br />
                  projects
                </span>
              </div>

              <div>
                <span className='about__info-title'>04+</span>
                <span className='about__info-name'>
                  Companies <br />
                  worked
                </span>
              </div>
            </div>
            <a href='#contact' className='button button--flex'>
              Contact me <i className='uil uil-message button__icon'></i>
            </a>
          </div>
        </div>

        <div className='home__scroll'>
          <a href='#skills' className='home__scroll-button button--flex'>
            <i className='uil uil-mouse-alt home__scroll-mouse'></i>
            <span className='home__scroll-name'>Scroll down</span>
            <i className='uil uil-arrow-down home__scroll-arrow'></i>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Welcome
