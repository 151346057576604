import React, { useState, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'

import ThemeConfigContext from '../../utility/context/themeConfig/ThemeConfigContext'
import { AuthContext } from '../../utility/context/auth/AuthContext'

const Header = () => {
  const { config, handleSkin } = useContext(ThemeConfigContext)
  const [isOpen, setIsOpen] = useState(false)

  const ThemeToggler = () => {
    if (config.skin === 'dark-theme') {
      return (
        <i
          className='uil uil-sun change-theme'
          id='theme-button'
          onClick={() => handleSkin('light')}
        ></i>
      )
    } else {
      return (
        <i
          className='uil uil-moon change-theme'
          id='theme-button'
          onClick={() => handleSkin('dark-theme')}
        ></i>
      )
    }
  }

  const navToggle = () => {
    setIsOpen(!isOpen)
  }

  const navLink = document.querySelectorAll('.nav__link')

  const linkAction = () => {
    setIsOpen(false)
  }
  navLink.forEach(n => n.addEventListener('click', linkAction))

  const { state, handleLogout } = useContext(AuthContext)
  const history = useHistory()

  const onLogout = () => {
    handleLogout()
    history.replace('/login')
  }

  return (
    <header className='header' id='header'>
      <nav className='nav container'>
        <a href='/' className='nav__logo'>
          DANIELRUIZ<span>.dev</span>
        </a>

        <div className={`nav__menu ${isOpen ? 'show-menu' : ''}`} id='nav-menu'>
          <ul className='nav__list grid'>
            <li className='nav__item'>
              <a href='#home' className='nav__link active-link'>
                <i className='uil uil-estate nav__icon'></i> Home
              </a>
            </li>
            <li className='nav__item'>
              <a href='#skills' className='nav__link'>
                <i className='uil uil-file-alt nav__icon'></i> Skills
              </a>
            </li>
            <li className='nav__item'>
              <a href='#portfolio' className='nav__link'>
                <i className='uil uil-scenery nav__icon'></i> Portfolio
              </a>
            </li>
            <li className='nav__item'>
              <a href='#blog' className='nav__link'>
                <i className='uil uil-scenery nav__icon'></i> Blog
              </a>
            </li>
            <li className='nav__item'>
              <a href='#contact' className='nav__link'>
                <i className='uil uil-message nav__icon'></i> Contact
              </a>
            </li>
            {
              state.logged &&
              <li className='nav__item cursor-pointer underline'>
                <Link className='nav__link' to='/admin'> Admin</Link>
              </li>
            }
            {
              state.logged &&
              <li className='nav__item cursor-pointer nav__link underline' onClick={onLogout}>
                Cerrar sesión
              </li>
            }
          </ul>
          <i className='uil uil-times nav__close' id='nav-close' onClick={navToggle}></i>
        </div>

        <div className='nav__btns'>
          {/* <!-- Theme change button --> */}
          <ThemeToggler />

          <div className='nav__toggle' id='nav-toggle' onClick={navToggle}>
            <i className='uil uil-apps'></i>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
