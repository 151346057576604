import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useForm } from '../../../utility/hooks/useForm'

import { Editor } from '@tinymce/tinymce-react'

import Select from 'react-select'

import authors from '../../../assets/data/authors.json'

import { crudAPI } from '../../../utility/services/crudAPI.service'

const initialState = {
  id: '',
  slug: '',
  title: '',
  author: '',
  category: '',
  subcategory: '',
  tags: ''
}

const EditPost = () => {
  const { id } = useParams()

  const [values, setValues, onChange, handleSelect] = useForm(initialState)

  const [enumData, setEnumData] = useState([])
  const [categories, setCategories] = useState([])
  /*
    DATOS SELECTS [AUTOR, CATEGORÍA, SUBCATEGORÍA]
  */
  const formatData = (data) => {
    return data.map(({ name, surname }) =>
      surname
        ? {
            value: name + ' ' + surname,
            label: name + ' ' + surname
          }
        : {
            value: name,
            label: name
          }
    )
  }
  useEffect(() => {
    crudAPI.getAll('categories').then((res) => {
      return setCategories({
        categories: res.categories.filter(
          (category) => category.type === 'category'
        ),
        subcategories: res.categories.filter(
          (category) => category.type === 'subcategory'
        )
      })
    })
  }, [])
  useEffect(() => {
    const cat = categories.categories && formatData(categories.categories)
    const sub = categories.subcategories && formatData(categories.subcategories)
    const at = formatData(authors)
    setEnumData({ cat, sub, at })
  }, [categories])

  /*
    DRAFT JS EDITOR
  */
  const getPostContent = () => {
    const user = JSON.parse(localStorage.getItem('user')) || ''
    crudAPI.getOneToken('posts', id, { 'x-token': user.token }).then((res) => {
      setValues(res.post)
    })
  }

  useEffect(() => {
    getPostContent()
  }, [])

  const handleSubmit = (e) => {
    const user = JSON.parse(localStorage.getItem('user')) || ''
    e.preventDefault()
    //console.log(values)
    crudAPI
      .updateToken('posts', id, values, { 'x-token': user.token })
      .then((res) => {
        console.log(res)
      })
  }

  return (
    <div className='edit-post'>
      <h1 className='mb-2'>Editar post</h1>
      <form className='' onSubmit={handleSubmit}>
        <div className='form__inputs grid mb-1'>
          <div className='form__content'>
            <label className='form__label'>Título</label>
            <input
              name='title'
              type='text'
              className='form__input'
              value={values && values.title}
              onChange={onChange}
            />
          </div>

          <div className='form__content'>
            <label className='form__label'>Slug</label>
            <input
              name='slug'
              type='text'
              className='form__input'
              value={values && values.slug}
              onChange={onChange}
            />
          </div>
        </div>

        <div className='form__inputs grid mb-2'>
          <div className='form__content'>
            <label className='form__label'>Autor</label>
            <Select
              name='author'
              isClearable={false}
              className='react-select'
              classNamePrefix='select'
              placeholder='Seleccionar...'
              options={enumData && enumData.at}
              value={{
                label: values && values.author,
                value: values && values.author
              }}
              onChange={handleSelect}
            />
          </div>

          <div className='form__content'>
            <label className='form__label'>Categoría</label>
            <Select
              name='category'
              isClearable={false}
              className='react-select'
              classNamePrefix='select'
              placeholder='Seleccionar...'
              options={enumData && enumData.cat}
              value={{
                label: values && values.category,
                value: values && values.category
              }}
              onChange={handleSelect}
            />
          </div>

          <div className='form__content'>
            <label className='form__label'>Subcategoría</label>
            <Select
              name='subcategory'
              isClearable={false}
              className='react-select'
              classNamePrefix='select'
              placeholder='Seleccionar...'
              options={enumData && enumData.sub}
              value={{
                label: values && values.subcategory,
                value: values && values.subcategory
              }}
              onChange={handleSelect}
            />
          </div>

          <div className='form__content'>
            <label className='form__label'>Tags</label>
            <input
              name='tags'
              type='text'
              className='form__input'
              value={values && values.tags}
              onChange={onChange}
            />
          </div>
        </div>

        <Editor
          apiKey='s5dj7gewdh1lo0wbomav9jyt3fnwd4keewn7zw9mryw2m6ke'
          value={values.content}
          init={{
            height: 550,
            menubar: false,
            plugins: [
              'advlist autolink lists link image',
              'charmap print preview anchor help',
              'searchreplace visualblocks codesample code',
              'insertdatetime media table paste wordcount',
              'emoticons toc'
            ],
            codesample_languages: [
              { text: 'HTML/XML', value: 'markup' },
              { text: 'JavaScript', value: 'javascript' },
              { text: 'CSS', value: 'css' },
              { text: 'PHP', value: 'php' },
              { text: 'Ruby', value: 'ruby' },
              { text: 'Python', value: 'python' },
              { text: 'Java', value: 'java' },
              { text: 'C', value: 'c' },
              { text: 'C#', value: 'csharp' },
              { text: 'C++', value: 'cpp' }
            ],
            toc_depth: 2,
            toc_class: 'tableContents',
            entity_encoding: 'raw',
            toolbar:
              'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | table image insertdatetime | preview searchreplace visualblocks codesample code emoticons toc | help '
          }}
          onEditorChange={(newContent) =>
            setValues({ ...values, content: newContent })
          }
        />

        <div>
          <button type='submit' className='form__button button--flex mt-2'>
            Actualizar post
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditPost
