import React, { useState, useEffect } from 'react'

import DataTable from 'react-data-table-component'

import { UilPen, UilTrashAlt } from '@iconscout/react-unicons'

import { useForm } from '../../../utility/hooks/useForm'
import { crudAPI } from '../../../utility/services/crudAPI.service'

import swal from 'sweetalert'

const initialState = {
  type: '',
  name: ''
}

const Categorias = () => {
  const user = JSON.parse(localStorage.getItem('user')) || ''
  const [categories, setCategories] = useState()
  const [ready, setReady] = useState(false)
  const [values, setValues, onChange] = useForm(initialState)
  const [selectedID, setSelectedID] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    crudAPI.getAllToken('categories', { 'x-token': user.token }).then((res) => {
      setCategories(res.categories)
    })
    setLoading(false)
  }, [values, user.token])

  const trashClick = (categoryId) => {
    //console.log(categoryId)
    swal({
      title: '¿Estás seguro?',
      text: 'Estás a punto de eliminar una categoría, después no podrá ser recuperada, ¿estás seguro?',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        swal('¡La categoría ha sido borrada!', {
          icon: 'success'
        })
        crudAPI
          .removeToken('categories', categoryId, { 'x-token': user.token })
          .then((res) => {
            //console.log(res)
            setCategories(
              categories.filter((category) => category._id !== categoryId)
            )
          })
      } else {
        swal('La categoría NO ha sido borrada')
      }
    })
  }

  useEffect(() => {
    if (values.name === '' || values.type === '') {
      setReady(false)
    } else {
      setReady(true)
    }
  }, [values])

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'Tipo',
      selector: (row) => row.type,
      sortable: true
    },
    {
      name: 'Acciones',
      allowOverflow: true,
      cell: (row) => {
        return (
          <div className='d-flex'>
            <span className='nav-link cursor-pointer'>
              <UilPen onClick={() => handleEdit(row._id)} size={15} />
            </span>
            <span className='nav-link cursor-pointer'>
              <UilTrashAlt
                onClick={() => trashClick(row._id)}
                className='mr-2'
                size={15}
              />
            </span>
          </div>
        )
      }
    }
  ]

  const handleEdit = (e) => {
    //console.log(e)
    setSelectedID(e)
    crudAPI
      .getOneToken('categories', e, { 'x-token': user.token })
      .then((res) => {
        setValues(res.category)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    //console.log(values)
    if (selectedID !== null) {
      crudAPI
        .updateToken('categories', selectedID, values, {
          'x-token': user.token
        })
        .then((res) => {
          if (res.ok) {
            // Alert actualizado correctamente
            setValues(initialState)
            setSelectedID(null)
          }
        })
    } else {
      crudAPI
        .addToken('categories', values, { 'x-token': user.token })
        .then((res) => {
          //console.log(res)
          setValues(initialState)
        })
    }
  }

  const clearForm = () => {
    setValues(initialState)
    setSelectedID(null)
  }

  if (loading) {
    return <p>Cargando ...</p>
  }

  return (
    <div>
      <div className='category__header d-flex mb-2'>
        <h3>Gestión de categorias y subcategorías</h3>
      </div>
      <div className='categories__container grid'>
        <div>
          <DataTable columns={columns} pagination={true} data={categories} />
        </div>

        <div>
          <div className='categories__title mb-1'>Editar categorías</div>
          <form onSubmit={handleSubmit}>
            <div className='form__inputs grid mb-1'>
              <div className='form__content'>
                <label className='form__label'>Tipo</label>
                <input
                  name='type'
                  type='text'
                  className='form__input'
                  value={values.type}
                  onChange={onChange}
                />
              </div>

              <div className='form__content'>
                <label className='form__label'>Nombre</label>
                <input
                  name='name'
                  type='text'
                  className='form__input'
                  value={values.name}
                  onChange={onChange}
                />
              </div>
            </div>

            <button
              type='submit'
              className='form__button button--flex'
              disabled={ready ? false : true}
            >
              {selectedID !== null ? 'Editar categoría' : 'Añadir categoría'}
            </button>

            <button
              onClick={clearForm}
              className='form__button button--flex float-right'
              disabled={ready ? false : true}
            >
              Limpiar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Categorias
