import React from 'react'
import Footer from '../components/footer/Footer'
import Sidebar from '../components/sidebar/Sidebar'

import '../../assets/css/layout-css.css'
import '../../assets/css/admin.css'

const AdminLayout = (props) => {
  // ** Props
  const { children } = props
  return (
    <div className='wrapper sidebar-floating'>
      <Sidebar />

      <section className='admin-content'>
        <div className='admin-content__inner'>{children}</div>
      </section>

      <footer className='admin-footer'>
        <Footer />
      </footer>
    </div>
  )
}

export default AdminLayout
