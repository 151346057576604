import React, { useState } from 'react'
import eurofins__logo from '../../assets/images/eu.png'
import fl__logo from '../../assets/images/fl.png'
import sf__logo from '../../assets/images/sf.png'
import intecca__logo from '../../assets/images/intecca.png'
import o2d__logo from '../../assets/images/o2d.png'
import office2000__logo from '../../assets/images/office2000.png'

const Qualification = () => {
  const [open, setOpen] = useState('work')

  const handleTab = (e) => {
    if (
      e.target
        .closest('div[data-target]')
        .getAttribute('data-target')
        .split('#')[1] === open
    ) {
      return
    } else {
      setOpen(
        e.target
          .closest('div[data-target]')
          .getAttribute('data-target')
          .split('#')[1]
      )
    }
  }
  return (
    <section className='qualification section'>
      <h2 className='section__title'>Qualifications</h2>
      <span className='section__subtitle'>My personal journey</span>

      <div className='qualification__container container'>
        <div className='qualification__tabs'>
          <div
            className={`qualification__button button--flex ${
              open === 'education' ? 'qualification__active' : null
            }`}
            data-target='#education'
            onClick={handleTab}
          >
            <i className='uil uil-graduation-cap qualification__icon'></i>{' '}
            Education
          </div>

          <div
            className={`qualification__button button--flex ${
              open === 'work' ? 'qualification__active' : null
            }`}
            data-target='#work'
            onClick={handleTab}
          >
            <i className='uil uil-briefcase-alt qualification__icon'></i> Work
          </div>
        </div>

        <div className='qualification__sections'>
          {/* <!--==================== QUALIFICATION CONTENT 1 ====================--> */}
          <div
            className={`qualification__content ${
              open === 'education' ? 'qualification__active' : ''
            }`}
            data-content
            id='education'
          >
            {/* <!--==================== EDUCATION 1 ====================--> */}
            <div className='qualification__data'>
              <div>
                <h3 className='qualification__title'>
                  University Expert Degree in Web Development specialised in IoT
                </h3>
                <div className='flex justify-between'>
                  <span className='qualification__subtitle'>Spain - UNED, Spanish Open University</span>
                  <div className='qualification__calendar flex items-start'>
                    <i className='uil uil-calendar-alt mr-05'></i>
                    2021
                  </div>
                </div>
              </div>

              <div className='qualification__divider flex flex-col items-center'>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            {/* <!--==================== EDUCATION 2 ====================--> */}
            <div className='qualification__data'>
              <div></div>

              <div className='qualification__divider'>
                <span className='qualification__rounder'></span>
                {/* <span className="qualification__line"></span> */}
              </div>

              <div>
                <h3 className='qualification__title'>
                  Higher Technician Certificate in Web Application Development
                </h3>
                <div className='flex justify-between'>
                  <span className='qualification__subtitle'>
                    Spain - CIFP Ponferrada
                  </span>
                  <div className='qualification__calendar flex items-start'>
                    <i className='uil uil-calendar-alt mr-05'></i>
                    2017 - 2020
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!--==================== QUALIFICATION CONTENT 2 ====================--> */}
          <div
            className={`qualification__content ${
              open === 'work' ? 'qualification__active' : ''
            }`}
            data-content
            id='work'
          > 
            {/* <!--==================== WORK 1 ====================--> */}
            <div className='qualification__data'>
              <div>
                <div className='flex flex-col'>
                  <div className='flex justify-between items-start'>
                    <h3 className='qualification__title'>
                      Front-end Developer
                    </h3>
                    <div className='qualification__calendar flex items-start'>
                      <i className='uil uil-calendar-alt mr-05'></i>
                      <span>01/2023 - Present</span>
                    </div>
                  </div>
                  <span className='flex items-center gap-2 qualification__subtitle'>
                    <img className="qualification__logo" src={eurofins__logo} alt="Eurofins logo" /> Eurofins - Dublin, Ireland
                  </span>
                </div>
                <div className='qualification__description text-justify'>
                  Developing web applications mainly using Angular and TypeScript being part of a SCRUM team. Other technologies used: Karma/Jasmine, Git, Azure DevOps, PrimeNG.
                </div>
              </div>

              <div className='qualification__divider flex flex-col items-center'>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            {/* <!--==================== WORK 2 ====================--> */}
            <div className='qualification__data'>
              <div></div>

              <div className='qualification__divider flex flex-col items-center'>
                <span className='qualification__rounder'></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <div className='flex flex-col'>
                  <div className='flex justify-between items-start'>
                    <h3 className='qualification__title'>
                      Developer Technical Support
                    </h3>
                    <div className='qualification__calendar'>
                      <i className='uil uil-calendar-alt mr-05'></i>
                      10/2021 - 01/2023
                    </div>
                  </div>
                  <span className='flex items-center gap-2 qualification__subtitle'>
                    <img src={sf__logo} alt='Salesforce logo' className='qualification__logo' /> Salesforce - Dublin, Ireland
                  </span>
                </div>
                <div className='qualification__description text-justify'>
                Creating personalized, dynamic messages and landing pages. Fluent in Marketing Cloud scripting languages and API integrations.
                </div>
              </div>
            </div>

            {/* <!--==================== WORK 3 ====================--> */}
            <div className='qualification__data'>
              <div>
                <div className='flex flex-col'>
                  <div className='flex justify-between items-start'>
                    <h3 className='qualification__title'>
                      Front-end Developer
                    </h3>
                    <div className='qualification__calendar'>
                      <i className='uil uil-calendar-alt mr-05'></i>
                      03/2021 - 07/2021
                    </div>                    
                  </div>
                  <span className='flex items-center gap-2 qualification__subtitle'>
                    <img src={intecca__logo} alt='INTECCA logo' className='qualification__logo' />
                    INTECCA - Ponferrada, Spain
                  </span>
                </div>
                <div className='qualification__description text-justify'>
                  Front-end development with React. Experience with React Hooks and fetching data from APIs. Development of IoT dashboards.
                </div>
              </div>

              <div className='qualification__divider flex flex-col items-center'>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            {/* <!--==================== WORK 4 ====================--> */}
            <div className='qualification__data'>
              <div></div>

              <div className='qualification__divider flex flex-col items-center'>
                <span className='qualification__rounder'></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <div className='flex flex-col'>
                  <div className='flex justify-between items-start'>
                    <h3 className='qualification__title'>
                      Web Developer
                    </h3>
                    <div className='qualification__calendar'>
                      <i className='uil uil-calendar-alt mr-05'></i>
                      01/2020 - 02/2021
                    </div>                    
                  </div>
                  <span className='flex items-center gap-2 qualification__subtitle'>
                    <img src={o2d__logo} alt='Oxígeno Digital logo' className='qualification__logo' />
                    Oxígeno Digital - Salamanca, Spain
                  </span>
                </div>
                <div className='qualification__description text-justify'>
                  Leading the creation of WordPress and PrestaShop complex solutions. Developing responsive websites, themes and plugins.
                </div>
              </div>
            </div>

            {/* <!--==================== WORK 5 ====================--> */}
            <div className='qualification__data'>
              <div>
                <div className='flex flex-col'>
                  <div className='flex justify-between items-start'>
                    <h3 className='qualification__title'>
                      Web Developer
                    </h3>
                    <div className='qualification__calendar flex items-start'>
                      <i className='uil uil-calendar-alt mr-05'></i>
                      <span>01/2019 - 01/2020</span>
                    </div>
                  </div>
                  <span className='flex items-center gap-2 qualification__subtitle'>
                    <img className="qualification__logo" src={fl__logo} alt="Farmacia Lisboa logo" /> Farmacia Lisboa - Salamanca, Spain
                  </span>
                </div>
                <div className='qualification__description text-justify'>
                  Evaluating, implementing and maintaining content management systems (WordPress and PrestaShop).
                </div>
              </div>

              <div className='qualification__divider flex flex-col items-center'>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            {/* <!--==================== WORK 6 ====================--> */}
            <div className='qualification__data'>
              <div></div>

              <div className='qualification__divider flex flex-col items-center'>
                <span className='qualification__rounder'></span>
              </div>

              <div>
                <div className='flex flex-col'>
                  <div className='flex justify-between items-start'>
                    <h3 className='qualification__title'>
                      Computer Technician
                    </h3>
                    <div className='qualification__calendar'>
                      <i className='uil uil-calendar-alt mr-05'></i>
                      03/2015 - 01/2019
                    </div>
                  </div>
                  <span className='flex items-center gap-2 qualification__subtitle'>
                    <img src={office2000__logo} alt='Office 2000 logo' className='qualification__logo' />
                    Office 2000 - Salamanca, Spain
                  </span>
                </div>
                <div className='qualification__description text-justify'>
                  Assisting clients with technical requests and implementations.
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Qualification
