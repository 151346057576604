import { fetchWrapper } from '../helpers/fetch-wrapper'
import config from './config'

function getAll(path) {
  if (config.key === 'crud_node') {
    return fetchWrapper.get(config.baseURL + config.apiURL + path)
  }
}
// Función getAll con autenticación del token
function getAllToken(path, headers) {
  if (config.key === 'crud_node') {
    return fetchWrapper.getWithToken(config.baseURL + config.apiURL + path, headers)
  }
}

function getOne(path, id) {
  if (config.key === 'crud_node') {
    return fetchWrapper.get(config.baseURL + config.apiURL + path + '/' + id)
  }
}
// Función getOne con autenticación del token
function getOneToken(path, id, headers) {
  if (config.key === 'crud_node') {
    return fetchWrapper.getWithToken(config.baseURL + config.apiURL + path + '/' + id, headers)
  }
}

function update(path, id, data) {
  if (config.key === 'crud_node') {
    return fetchWrapper.put(
      config.baseURL + config.apiURL + path + '/' + id,
      data
    )
  }
}
// Función update con autenticación del token
function updateToken(path, id, data, headers) {
  if (config.key === 'crud_node') {
    return fetchWrapper.putWithToken(
      config.baseURL + config.apiURL + path + '/' + id, data, headers
    )
  }
}

function add(path, data) {
  if (config.key === 'crud_node') {
    return fetchWrapper.post(config.baseURL + config.apiURL + path, data)
  }
}
// Función add con autenticación del token
function addToken(path, data, headers) {
  if (config.key === 'crud_node') {
    return fetchWrapper.postWithToken(config.baseURL + config.apiURL + path, data, headers)
  }
}

function remove(path, id) {
  if (config.key === 'crud_node') {
    return fetchWrapper._delete(
      config.baseURL + config.apiURL + path + '/' + id
    )
  }
}
// Función remove con autenticación del token
function removeToken(path, id, headers) {
  if (config.key === 'crud_node') {
    return fetchWrapper._deleteWithToken(
      config.baseURL + config.apiURL + path + '/' + id, headers
    )
  }
}

// Función para buscar por tag
function findData(path, tag) {
  if (config.key === 'crud_node') {
    return fetchWrapper.post(config.baseURL + config.apiURL + path, tag)
  }
}

function startChecking(path, headers) {
  return fetchWrapper.getWithToken(config.baseURL + config.apiURL + path, headers)
}


export const crudAPI = {
  getAll,
  getAllToken,
  getOne,
  getOneToken,
  add,
  addToken,
  update,
  updateToken,
  remove,
  removeToken,
  findData,
  startChecking
}
