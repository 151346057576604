import React, { useReducer, useEffect } from 'react'
import { crudAPI } from '../../services/crudAPI.service'

import { AuthContext } from './AuthContext'
import { AuthReducer } from './AuthReducer'

const AuthState = (props) => {
  const initialState = {
    isTokenValidated: false
  }

  const [state, dispatch] = useReducer(AuthReducer, initialState)

  const handleLogin = (item) => {
    dispatch({
      type: '[auth] login',
      payload: {
        name: item.name,
        uid: item.uid,
        token: item.token
      }
    })
  }

  const handleLogout = () => {
    localStorage.clear()
    dispatch({
      type: '[auth] logout'
    })
  }

  useEffect(() => {
    const token = localStorage.getItem('token') || ''

    crudAPI
      .startChecking('auth/renew', { 'x-token': token })
      .then((res) => {
        if (res.ok) {
          localStorage.setItem('token', res.token)
          localStorage.setItem('token-init-date', new Date().getTime())

          handleLogin(res)
        } else {
          dispatch({
            type: '[auth] logout'
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: '[auth] logout'
        })
        //console.log(err)
      })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        state,
        handleLogin,
        handleLogout
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthState
