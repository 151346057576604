import React, { useState, useEffect, Fragment } from 'react'

import portada from '../../assets/images/blog/react-router.jpg'

import { useHistory } from 'react-router-dom'
import ScrollUp from '../../components/scrollUp/ScrollUp'
import { crudAPI } from '../../utility/services/crudAPI.service'
import { Helmet } from 'react-helmet'

const Blog = () => {
  const [posts, setPosts] = useState()

  useEffect(() => {
    crudAPI.getAll('posts')
      .then(res => {
        //console.log(res)
        setPosts(res.posts)
      })
  }, [])

  const history = useHistory()
  const handleRoute = (slug, postId) => {
    history.push({ pathname: `/post/${slug}`, state: { postId } })
  }

  return (
    <Fragment>
      <Helmet>
        <title>🗓  Blog - Recent posts | Daniel Ruiz </title>
        <meta name="description" content="Front-end developer specialised developing interactive and user-friendly interfaces." />
      </Helmet>
      <main className='main'>
        <section className='blog section'>
          <h2 className='section__title'>Blog</h2>
          <span className='section__subtitle'>Recent posts</span>

          <div className='blog__container container grid'>
            {
              posts && posts.map((post) => (
                <div key={post._id} className='blog__post' onClick={() => handleRoute(post.slug, post._id)}>
                  <div className='blog__img'>
                    <img src={portada} alt='imagen portada' />
                  </div>
                  <div className='blog__content'>
                    <h4 className='blog__title'>
                      {post.title}
                    </h4>
                    <span className='blog__info'>
                      {post.date} · {post.author}
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        </section>
      </main>
      <ScrollUp />
    </Fragment>
  )
}

export default Blog
