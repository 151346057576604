import React, { Fragment } from 'react'
import Welcome from './Welcome'
import Skills from './Skills'
import Qualification from './Qualification'
import Portfolio from './Portfolio'
import Contact from './Contact'
import ScrollUp from '../../components/scrollUp/ScrollUp'
import BlogHome from './BlogHome'

import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <Fragment>
      <Helmet>
        <title>💻  Front-end developer | Daniel Ruiz </title>
        <meta name="description" content="Front-end developer specialised developing interactive and user-friendly interfaces." />
      </Helmet>
      <main className="main">
        <Welcome />
        <Skills />
        <Qualification />
        <Portfolio />
        <BlogHome />
        <Contact />
      </main>
      <ScrollUp />
    </Fragment>
  )
}

export default Home
