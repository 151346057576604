import React, { useState, useEffect } from 'react'
import { crudAPI } from '../../utility/services/crudAPI.service'
import { Link } from 'react-router-dom'

const BlogHome = () => {
  const [posts, setPosts] = useState()

  useEffect(() => {
    crudAPI.getAll('posts').then((res) => {
      //console.log(res)
      setPosts(res.posts)
    })
  }, []) 

  return (
    <section className='blog-home section' id='blog'>
      <h2 className='section__title'>Blog</h2>
      <span className='section__subtitle'>Recent posts</span>

      <div className='blog-home__container container grid'>
        {posts &&
          posts.slice(0, 3).map((item) => (
            <Link key={item.slug} to={`/post/${item.slug}`}>
              <div className='blog-home__post'>
                <div className='blog-home__title'>{item.title}</div>
                <span className='blog-home__meta blog__info'>
                  {item.author}
                </span>
              </div>
            </Link>
          ))}
      </div>
    </section>
  )
}

export default BlogHome
