import React, { useContext } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import AdminLayout from '../layout/dashboard/AdminLayout'
import { AuthContext } from '../utility/context/auth/AuthContext'
import AddPost from '../views/admin/blog/AddPost'
import EditPost from '../views/admin/blog/EditPost'
import Posts from '../views/admin/blog/Posts'
import Categorias from '../views/admin/categorias/Categorias'
import Dashboard from '../views/admin/Dashboard'
import { PrivateRoute } from './PrivateRoute'

const DashboardRoutes = () => {

  const { state } = useContext(AuthContext)

  return (
    <Switch>
      <AdminLayout>
        <PrivateRoute exact path='/' component={Dashboard} isAuth={state.logged} />
        <PrivateRoute exact path='/admin/blog/posts' component={Posts} isAuth={state.logged} />
        <PrivateRoute exact path='/admin/blog/edit/:id' component={EditPost} isAuth={state.logged} />
        <PrivateRoute exact path='/admin/blog/add' component={AddPost} isAuth={state.logged} />
        <PrivateRoute exact path='/admin/categorias' component={Categorias} isAuth={state.logged} />
        <Redirect to='/admin' />
      </AdminLayout>
    </Switch>
  )
}

export default DashboardRoutes
