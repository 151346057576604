import React from 'react'

const Footer = () => {
  return (
    <div className='footer-content'>
      <span>© Daniel Ruiz. All rights reserved</span>
    </div>
  )
}

export default Footer
