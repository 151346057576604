import React, { Fragment, useContext } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Home from '../views/home'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Post from '../views/blog/Post'
import Blog from '../views/blog/Blog'
import { PublicRoute } from './PublicRoute'
import { AuthContext } from '../utility/context/auth/AuthContext'

const HomeRoutes = () => {

  const { state } = useContext(AuthContext)

  return (
    <Fragment>
      <Header />
      <Switch>
        <PublicRoute exact path='/' component={Home} isAuth={state.logged} />
        <PublicRoute exact path='/blog' component={Blog} isAuth={state.logged} />
        <PublicRoute path='/post/:id' component={Post} isAuth={state.logged} />
        <Redirect to='/' />
      </Switch>
      <Footer />
    </Fragment>
  )
}

export default HomeRoutes
