import React, { useState, useReducer, useEffect } from 'react'

import ThemeConfigContext from './ThemeConfigContext'
import ThemeConfigReducer from './ThemeConfigReducer'

const ThemeConfigState = (props) => {
  let initialState = {
    skin: 'light'
  }

  const [state, dispatch] = useReducer(ThemeConfigReducer, initialState)

  const [scroll, setScroll] = useState(0)

  const sections = document.querySelectorAll('section[id]')

  window.addEventListener('scroll', () => {
    setScroll(window.pageYOffset)
  })

  useEffect(() => {
    const nav = document.getElementById('header')
    const scrollUp = document.getElementById('scroll-up')
    // When the scroll is greater than 200 viewport height, add the scroll-header class to the header tag
    if (nav) {
      scroll > 0 && scroll >= 80
        ? nav.classList.add('scroll-header')
        : nav.classList.remove('scroll-header')
    }
    // When the scroll is higher than 560 viewport height, add the show-scroll class to the a tag with the scroll-top class
    if (scrollUp) {
      scroll > 0 && scroll >= 560
        ? scrollUp.classList.add('show-scroll')
        : scrollUp.classList.remove('show-scroll')
    }
  }, [scroll])

  useEffect(() => {
    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight
      const sectionTop = current.offsetTop - 50
      const sectionId = current.getAttribute('id')

      if (scroll > sectionTop && scroll <= sectionTop + sectionHeight) {
        document
          .querySelector('.nav__menu a[href*=' + sectionId + ']')
          .classList.add('active-link')
      } else {
        document
          .querySelector('.nav__menu a[href*=' + sectionId + ']')
          .classList.remove('active-link')
      }
    })
  }, [scroll, sections])

  useEffect(() => {
    // ** Get Body Tag
    const element = window.document.body

    // ** Remove all classes from Body on mount
    element.classList.remove(...element.classList)

    // ** If skin is not light add skin class
    if (state.skin !== 'light') {
      element.classList.add(state.skin)
    }
  }, [state.skin])

  const handleSkin = (value) => {
    dispatch({ type: 'HANDLE_SKIN', payload: value })
  }

  return (
    <ThemeConfigContext.Provider
      value={{
        config: state,
        handleSkin
      }}
    >
      {props.children}
    </ThemeConfigContext.Provider>
  )
}

export default ThemeConfigState
