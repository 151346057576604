import React, { Fragment } from 'react'
import readTime from 'read-time'

import { UilSchedule, UilClockTwo, UilUser, UilTagAlt } from '@iconscout/react-unicons'

function getReadTime({ rawContent }) {
  const { m } = readTime(rawContent)
  return `${m} minutos de lectura`
}

const ArticleMeta = ({ rawContent = '', postContent }) => {
  const date = new Date(postContent && postContent.createdAt)
  const createdDate = date.toLocaleString(
    'es-ES', { day: 'numeric', month: 'long', year: 'numeric' }
  )
  const tags = postContent && postContent.tags.split(',')
  return (
    <Fragment>
      <span className='article__meta'><UilSchedule size={16} />{createdDate}</span>
      <span className='article__meta'><UilClockTwo size={16} /> {getReadTime({ rawContent })} </span>
      <span className='article__meta'><UilUser size={16} /> {postContent && postContent.author}</span>
      <span className='article__meta'><UilTagAlt size={16} />
        {
          tags &&
          tags.map((tag) => (
            <span key={tag} className='post__tag'>{tag}</span>
          ))
        }
      </span>
    </Fragment>
  )
}

export default ArticleMeta
