import React, { useState } from 'react'
import { FaHtml5, FaCss3Alt, FaReact, FaAngular, FaNodeJs } from 'react-icons/fa'
import { DiJavascript1, DiMongodb } from 'react-icons/di'
import { GrMysql } from 'react-icons/gr'

const Skills = () => {
  const [open, setOpen] = useState('skills__content_1')

  const toggleSkills = (e) => {
    if (open === e.target.parentNode.closest('div[id]').id) {
      setOpen('')
    } else {
      setOpen(e.target.parentNode.closest('div[id]').id)
    }
  }

  return (
    <section className='skills section' id='skills'>
      <h2 className='section__title'>Skills</h2>
      <span className='section__subtitle'>My technical level</span>

      <div className='skills__container container grid'>
        <div>
          {/* <!--==================== SKILLS 1 ====================--> */}
          <div
            id='skills__content_1'
            className={`skills__content ${
              open === 'skills__content_1' ? 'skills__open' : 'skills__close'
            }`}
          >
            <div className='skills__header' onClick={toggleSkills}>
              <i className='uil uil-brackets-curly skills__icon'></i>

              <div>
                <h1 className='skills__title'>Front-end developer</h1>
                <span className='skills__subtitle'>More than 4 years</span>
              </div>

              <i className='uil uil-angle-down skills__arrow'></i>
            </div>

            <div className='skills__list grid'>
              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>HTML</h3>
                  <span className='skills__subicon'>
                    <FaHtml5 color='#e34c26' />
                  </span>
                </div>
                <div className='skills__description'>
                  Extensive knowledge of HTML5, semantic markup and Document Object Model manipulation.
                </div>
              </div>

              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>CSS</h3>
                  <span className='skills__subicon'>
                    <FaCss3Alt color='#3C99DC' />
                  </span>
                </div>
                <div className='skills__description'>
                  Proficent in CSS3, responsive design, CSS preprocessors (SASS, LESS) and CSS frameworks (Bootstrap, Tailwind CSS).
                </div>
              </div>

              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>JavaScript</h3>
                  <span className='skills__subicon skills__js'>
                    <DiJavascript1 />
                  </span>
                </div>
                <div className='skills__description'>
                  Strong knowledge of JavaScript, ES6, DOM manipulation, event handling, REST API and asynchronous programming.
                </div>
              </div>

              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>React</h3>
                  <span className='skills__subicon'>
                    <FaReact color='#61DBFB' />
                  </span>
                </div>
                <div className='skills__description'>
                  Experience in developing dynamic web applications using React, JSX, and state management libraries (Redux, Context API).
                </div>
              </div>

              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>Angular</h3>
                  <span className='skills__subicon'>
                    <FaAngular color='#dd1b16' />
                  </span>
                </div>
                <div className='skills__description'>
                  Proficent in Angular framework, Typescript and building scalable web applications.  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {/* <!--==================== SKILLS 2 ====================--> */}
          <div
            id='skills__content_2'
            className={`skills__content ${
              open === 'skills__content_2' ? 'skills__open' : 'skills__close'
            }`}
          >
            <div className='skills__header' onClick={toggleSkills}>
              <i className='uil uil-server-network skills__icon'></i>

              <div>
                <h1 className='skills__title'>Back-end developer</h1>
                <span className='skills__subtitle'>More than 2 years</span>
              </div>

              <i className='uil uil-angle-down skills__arrow'></i>
            </div>

            <div className='skills__list grid'>

              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>Node JS</h3>
                  <span className='skills__subicon'>
                    <FaNodeJs color='#3C873A' />
                  </span>
                </div>
                <div className='skills__description'>
                  Experience in Node.js, asynchronous programming, and server-side JavaScript.
                </div>
              </div>

              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>Express</h3>
                  <span className='skills__subicon skills__express'>
                    express
                  </span>
                </div>
                <div className='skills__description'>
                  Experience in Express framework and building RESTful APIs.
                </div>
              </div>

              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>MongoDB</h3>
                  <span className='skills__subicon'>
                    <DiMongodb color='#4DB33D' />
                  </span>
                </div>
                <div className='skills__description'>
                  Strong knowledge of MongoDB, Mongoose, NoSQL databases and data modeling.
                </div>
              </div>

              <div className='skills__data'>
                <div className='skills__titles'>
                  <h3 className='skills__name'>MySQL</h3>
                  <span className='skills__subicon'>
                    <GrMysql color='#00758F' />
                  </span>
                </div>
                <div className='skills__description'>
                  Proficent in MySQL database management, SQL queries, and data modeling.
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Skills
