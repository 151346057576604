import React, { useState, useEffect } from 'react'

import DataTable from 'react-data-table-component'

import { Link } from 'react-router-dom'
import { UilPen, UilTrashAlt } from '@iconscout/react-unicons'
import { crudAPI } from '../../../utility/services/crudAPI.service'

import swal from 'sweetalert'

const Posts = () => {
  const [posts, setPosts] = useState()
  const user = JSON.parse(localStorage.getItem('user')) || ''
  useEffect(() => {
    crudAPI
      .getAllToken('posts', { 'x-token': user.token })
      .then((data) => {
        setPosts(data.posts)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [user.token])

  const trashClick = (postId) => {
    swal({
      title: '¿Estás seguro?',
      text: 'Estás a punto de eliminar el post, después no podrá ser recuperado, ¿estás seguro?',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        swal('¡El post ha sido borrado!', {
          icon: 'success'
        })
        crudAPI
          .removeToken('posts', postId, { 'x-token': user.token })
          .then((res) => {
            setPosts(posts.filter((post) => post._id !== postId))
          })
      } else {
        swal('El post NO ha sido borrado')
      }
    })
  }

  const columns = [
    {
      name: 'Título',
      selector: (row) => row.title,
      sortable: true
    },
    {
      name: 'Autor',
      selector: (row) => row.author,
      sortable: true
    },
    {
      name: 'Categoría',
      selector: (row) => row.category,
      sortable: true
    },
    {
      name: 'Subcategoría',
      selector: (row) => row.subcategory,
      sortable: true
    },
    {
      name: 'Tags',
      selector: (row) => row.tags,
      sortable: true
    },
    {
      name: 'Acciones',
      allowOverflow: true,
      cell: (row) => {
        return (
          <div className='d-flex'>
            <Link to={`/admin/blog/edit/${row._id}`}>
              <UilPen size={15} />
            </Link>
            <span className='nav-link cursor-pointer'>
              <UilTrashAlt
                onClick={() => trashClick(row._id)}
                className='mr-2'
                size={15}
              />
            </span>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <h1>Posts</h1>
      <DataTable columns={columns} pagination={true} data={posts} />
    </div>
  )
}

export default Posts
