import React, { Fragment, useContext } from 'react'
import swal from 'sweetalert'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import ScrollUp from '../../components/scrollUp/ScrollUp'

import { useForm } from '../../utility/hooks/useForm'
import { crudAPI } from '../../utility/services/crudAPI.service'

import { AuthContext } from '../../utility/context/auth/AuthContext'

import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const initialState = {
  email: '',
  pass: ''
}

const Login = () => {

  const [values, , onChange,] = useForm(initialState)

  const { handleLogin } = useContext(AuthContext)
  const history = useHistory()
  const handleSubmit = (e) => {
    e.preventDefault()

    crudAPI.add('auth', values)
      .then((res) => {
        if (res.ok) {
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('token-init-date', new Date().getTime())

          handleLogin(res.data)
          history.replace('/admin')
        } else {
          console.log(res)
        }
      })
      .catch((error) => {
        console.log('Error: ', error)
        swal({
          title: "¡Ups, algo no ha ido bien!",
          text: error.data.msg || 'Revisa los datos introducidos.',
          icon: "error",
        });
      })
  }

  return (
    <Fragment>
      <Helmet>
        <title>🔐 Login | Daniel Ruiz </title>
        <meta name="description" content="Front End developer specialised developing interactive and user-friendly interfaces." />
      </Helmet>
      <Header />

      <main className='main'>
        <section className='login section'>
          <h2 className='section__title'>Login</h2>
          <span className='section__subtitle'>Dashboard access</span>
          <div className='login__container container grid'>
            <form className='login__form grid' onSubmit={handleSubmit}>
              <div className='login__inputs grid'>
                <div className='login__content'>
                  <label className='login__label'>Email</label>
                  <input
                    name='email'
                    type='email'
                    className='login__input'
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className='login__content'>
                <label className='login__label'>Password</label>
                <input
                  name='pass'
                  type='password'
                  className='login__input'
                  onChange={onChange}
                  autoComplete="off"
                />
              </div>

              <div>
                <button type='submit' className='button button--flex'>
                  Login
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
      <ScrollUp />
      <Footer />
    </Fragment>
  )
}

export default Login
