import React from 'react'
// ** Router Components
import ThemeConfigState from './utility/context/themeConfig/ThemeConfigState';

import Router from './router/Router'
import AuthState from './utility/context/auth/AuthState';

function App() {

  return (
    <AuthState>
      <ThemeConfigState>
        <Router />
      </ThemeConfigState>
    </AuthState>
  )
}

export default App;
