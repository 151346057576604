import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import navigation from './navigation'

const MainMenu = () => {
  const [open, setOpen] = useState(false)
  const handleClass = (e) => {
    e.preventDefault()
    setOpen(!open)
  }

  return (
    <div className='main-menu'>
      <ul className='navigation'>
        {navigation.map((item) => {
          return item.children ? (
            <li
              key={item.id}
              className={`navigation__item has-sub ${open ? 'open' : ''} `}
            >
              <Link to='/' onClick={handleClass}>
                {item.icon}
                <span className='menu-title text-truncate'>{item.title}</span>
              </Link>
              <ul className='navigation__child'>
                {item.children.map((child) => (
                  <li key={child.id} className={'navigation__item'}>
                    <Link
                      className='d-flex align-items-center'
                      to={child.navLink}
                    >
                      {child.icon}
                      <span className='menu-item text-truncate'>
                        {child.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li key={item.id} className={'navigation__item'}>
              <Link to={item.navLink}>
                {item.icon}
                <span className='menu-item text-truncate'>{item.title}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MainMenu
