import React from 'react'

const BrowserCompatibility = (props) => {
  const functionName = props.functionName
  return (
    <div>
      <p
        className='ciu_embed'
        data-feature={functionName}
        data-periods='future_1,current,past_1,past_2'
        data-accessible-colours='false'
      >
        <span>
          Data on support for the mdn-api__CustomEvent feature across the major
          browsers
        </span>
      </p>
    </div>
  )
}

export default BrowserCompatibility
