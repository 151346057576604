import { api } from '../services/api'

const get = (url) => {
  return api.get(url)
    .then(res => handleResponse(res))
    .catch(err => {
      return err
    })
}

const getWithToken = (url, headers) => {
  return api.get(url, { headers: headers })
    .then(res => handleResponse(res))
    .catch(err => {
      return handleResponse(err.response)
    })
}

const post = (url, body) => {
  return api.post(url, body)
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      return handleResponse(err.response)
    })
}
const postWithToken = (url, body, headers) => {
  return api.post(url, body, { headers: headers })
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      return handleResponse(err.response)
    })
}

const put = (url, body) => {
  return api.put(url, body)
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      return handleResponse(err.response)
    })
}
const putWithToken = (url, body, headers) => {
  return api.put(url, body, { headers: headers })
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      return handleResponse(err.response)
    })
}

const _delete = (url) => {
  return api.delete(url)
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      return handleResponse(err.response)
    })
}
const _deleteWithToken = (url, headers) => {
  return api.delete(url, { headers: headers })
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      return handleResponse(err.response)
    })
}

function handleResponse(response) {
  const data = response
  if (data.status === 200 || data.status === 201) {
    return data.data
  } else {
    const error = data
    return Promise.reject(error)
  }
}

export const fetchWrapper = {
  get,
  getWithToken,
  post,
  postWithToken,
  put,
  putWithToken,
  _delete,
  _deleteWithToken
};