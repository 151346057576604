import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'


import projects from '../../assets/data/portfolio.json'

const Portfolio = () => {
  // install Swiper modules
  SwiperCore.use([Navigation, Pagination])
  return (
    <section className='portfolio section' id='portfolio'>
      <h2 className='section__title'>Portfolio</h2>
      <span className='section__subtitle'>Most recent work</span>
      <div className='portfolio__container container'>
        <Swiper
          color={'hsl(250, 69%, 61%)'}
          navigation={{
            nextEl: '.swiper-button-next-custom',
            prevEl: '.swiper-button-prev-custom'
          }}
          pagination={{ el: '.custom-swiper-pagination', clickable: true }}
        >
          {
            projects.map((project, index) => (
              <SwiperSlide className='portfolio__content grid' key={index}>
                <img src={project.image} alt='' className='portfolio__img' />

                <div className='portfolio__data'>
                  <h3 className='portfolio__title'>{project.title}</h3>
                  <div className='portfolio__description'>
                    {project.description}
                    <div className='article__metas'>
                      {
                        project.tags.map((tag, index) => (
                          <span className='post__tag' key={index}>{tag}</span>
                        ))
                      }
                    </div>
                  </div>
                  {
                    project.link ? (
                      <a
                        href={project.link}
                        target='_blank'
                        rel='noreferrer'
                        className='button button--flex button--small portfolio__button'
                      >
                        Demo <i className='uil uil-arrow-right button__icon'></i>
                      </a>
                    ) : (
                      <span className='button button--flex button--small portfolio__button'>
                        Available soon
                      </span>
                    )
                  }
                  
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
        <div className='swiper-button-next swiper-button-next-custom'>
          <i className='uil uil-angle-right-b swiper-portfolio-icon'></i>
        </div>
        <div className='swiper-button-prev swiper-button-prev-custom'>
          <i className='uil uil-angle-left-b swiper-portfolio-icon'></i>
        </div>
        <div className='swiper-pagination custom-swiper-pagination'></div>
      </div>
    </section>
  )
}

export default Portfolio
