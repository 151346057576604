import { useState } from 'react'

export const useForm = (initialState = {}) => {

  const [values, setValues] = useState(initialState)

  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const handleSelect = (value, action) => {
    if (value) {
      setValues({
        ...values,
        [action.name]: value.value
      })
    } else {
      setValues({
        ...values,
        [action.name]: null
      })
    }
  }


  return [values, setValues, onChange, handleSelect]
}


