import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import portada from '../../assets/images/blog/react-router.jpg'

import ScrollUp from '../../components/scrollUp/ScrollUp'

import { crudAPI } from '../../utility/services/crudAPI.service'

import parse from 'html-react-parser'
import ArticleMeta from '../../components/ui/ArticleMeta'

import Prism from 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import RelatedPosts from '../../components/ui/RelatedPosts'
import { Helmet } from 'react-helmet'
import BrowserCompatibility from '../../components/ui/BrowserCompatibility'

const Post = ({ history }) => {
  const { id } = useParams()
  const [slugPost, setSlugPost] = useState(id)
  const [post, setPost] = useState()
  const [content, setContent] = useState()
  const [redirect, setRedirect] = useState(null)

  useEffect(() => {
    crudAPI
      .getOne('posts', slugPost)
      .then((res) => {
        //console.log(res)
        setPost(res.post)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [slugPost])

  useEffect(() => {
    setContent(post && post.content)
  }, [post])

  useEffect(() => {
    Prism.highlightAll()
  }, [content])

  useEffect(() => {
    if (redirect !== null) {
      history.replace(`/post/${redirect}`)
      setSlugPost(redirect)
    }
  }, [redirect, history])

  const tag = 'React'

  return (
    <main className='main'>
      <Helmet>
        <title>📝 | Daniel Ruiz </title>
        <meta
          name='description'
          content='Front-end developer specialised developing interactive and user-friendly interfaces.'
        />
      </Helmet>
      <section className='post section'>
        <div className='post__container container grid'>
          <div className='post__content'>
            <article>
              <header className='article__header mb-2'>
                <h1 className='article__title'>{post && post.title}</h1>
                <div className='article__metas mb-1'>
                  <ArticleMeta rawContent={content} postContent={post} />
                </div>
                <picture>
                  <img src={portada} alt='imagen portada' />
                </picture>
              </header>

              <div className='article__content'>
                {content && parse(content)}
                <BrowserCompatibility functionName={'mdn-api__CustomEvent'} />
              </div>
            </article>
          </div>

          <div className='post__sidebar'>
            <h3 className='mb-1'>Related posts:</h3>
            <RelatedPosts targetTag={tag} changeSlug={setRedirect} />
          </div>
        </div>
      </section>
      <ScrollUp />
    </main>
  )
}

export default Post
