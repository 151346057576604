import React, { useState, useEffect, useContext } from 'react'

import { useForm } from '../../../utility/hooks/useForm'

import { Editor } from '@tinymce/tinymce-react'

import Select from 'react-select'

import authors from '../../../assets/data/authors.json'

import { crudAPI } from '../../../utility/services/crudAPI.service'
import { AuthContext } from '../../../utility/context/auth/AuthContext'

const initialState = {
  slug: '',
  title: '',
  author: null,
  category: null,
  subcategory: null,
  tags: '',
  content: ''
}

const AddPost = () => {

  const { dispatch } = useContext(AuthContext)
  const [values, setValues, onChange, handleSelect] = useForm(initialState)
  const [enumData, setEnumData] = useState()
  const [categories, setCategories] = useState([])

  const user = JSON.parse(localStorage.getItem('user')) || ''

  /*
    DATOS SELECTS [AUTOR, CATEGORÍA, SUBCATEGORÍA]
  */
  const formatData = (data) => {
    return data.map(({ name, surname }) => (
      surname ? (
        {
          value: name + ' ' + surname,
          label: name + ' ' + surname
        }
      ) : (
        {
          value: name,
          label: name
        }
      )
    ))
  }
  useEffect(() => {
    crudAPI.getAll('categories')
      .then((res) => {
        return setCategories({
          categories: res.categories.filter((category) => category.type === 'category'),
          subcategories: res.categories.filter((category) => category.type === 'subcategory')
        })
      })
  }, [])

  useEffect(() => {
    const cat = categories.categories && formatData(categories.categories)
    const sub = categories.subcategories && formatData(categories.subcategories)
    const at = formatData(authors)
    setEnumData({ cat, sub, at })
  }, [categories])

  /*
    EDITOR
  */
  const onEditorChange = (e) => {
    const content = e
    console.log(content)
    setValues({
      ...values,
      content
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    crudAPI.addToken('posts', values, { 'x-token': user.token })
      .then((res) => {
        if (res.ok) {
          setValues(initialState)
        }
      })
      .catch((error) => {
        if (error.data.msg === 'Token no válido') {
          dispatch({
            type: '[auth] logout'
          })
        }
      })
  }

  return (
    <div className='edit-post'>
      <h1 className='mb-2'>Añadir post</h1>
      <form className="" onSubmit={handleSubmit}>
        <div className="form__inputs grid mb-1">
          <div className="form__content">
            <label className="form__label">Título</label>
            <input
              name="title"
              type="text"
              className="form__input"
              value={values && values.title}
              onChange={onChange}
            />
          </div>

          <div className="form__content">
            <label className="form__label">Slug</label>
            <input
              name="slug"
              type="text"
              className="form__input"
              value={values && values.slug}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="form__inputs grid mb-2">
          <div className="form__content">
            <label className="form__label">Autor</label>
            <Select
              name='author'
              isClearable={false}
              className='react-select'
              classNamePrefix='select'
              placeholder='Seleccionar...'
              options={enumData && enumData.at}
              value={
                values.author === null
                  ? null
                  :
                  {
                    label: values && values.author,
                    value: values && values.author
                  }
              }
              onChange={handleSelect}
            />
          </div>

          <div className="form__content">
            <label className="form__label">Categoría</label>
            <Select
              name='category'
              isClearable={false}
              className='react-select'
              classNamePrefix='select'
              placeholder='Seleccionar...'
              options={enumData && enumData.cat}
              value={
                values.category === null
                  ? null
                  :
                  {
                    label: values && values.category,
                    value: values && values.category
                  }
              }
              onChange={handleSelect}
            />
          </div>

          <div className="form__content">
            <label className="form__label">Subcategoría</label>
            <Select
              name='subcategory'
              isClearable={false}
              className='react-select'
              classNamePrefix='select'
              placeholder='Seleccionar...'
              options={enumData && enumData.sub}
              value={
                values.subcategory === null
                  ? null
                  :
                  {
                    label: values && values.subcategory,
                    value: values && values.subcategory
                  }
              }
              onChange={handleSelect}
            />
          </div>

          <div className="form__content">
            <label className="form__label">Tags</label>
            <input
              name="tags"
              type="text"
              className="form__input"
              value={values && values.tags}
              onChange={onChange}
            />
          </div>
        </div>

        <Editor
          name='content'
          apiKey="s5dj7gewdh1lo0wbomav9jyt3fnwd4keewn7zw9mryw2m6ke"
          value={values.content}
          init={{
            height: 550,
            menubar: false,
            plugins: [
              'advlist autolink lists link image',
              'charmap print preview anchor help',
              'searchreplace visualblocks codesample code',
              'insertdatetime media table paste wordcount',
              'emoticons toc'
            ],
            codesample_languages: [
              { text: 'HTML/XML', value: 'markup' },
              { text: 'JavaScript', value: 'javascript' },
              { text: 'CSS', value: 'css' },
              { text: 'PHP', value: 'php' },
              { text: 'Ruby', value: 'ruby' },
              { text: 'Python', value: 'python' },
              { text: 'Java', value: 'java' },
              { text: 'C', value: 'c' },
              { text: 'C#', value: 'csharp' },
              { text: 'C++', value: 'cpp' }
            ],
            toc_depth: 2,
            toc_class: "tableContents",
            toolbar:
              'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | table image insertdatetime | preview searchreplace visualblocks codesample code emoticons toc | help '
          }}
          onEditorChange={onEditorChange}
        />
        <div>
          <button type='submit' className="form__button button--flex mt-2">
            Añadir post
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddPost
