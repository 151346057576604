import { UilCircle, UilBookOpen, UilEstate, UilTag } from '@iconscout/react-unicons'

export default [
  {
    id: 'home',
    title: 'Home',
    icon: <UilEstate size='20' className='mr-05' />,
    navLink: '/admin'
  },
  {
    id: 'blog',
    title: 'Blog',
    icon: <UilBookOpen size='20' className='mr-05' />,
    children: [
      {
        id: 'posts',
        title: 'Posts',
        icon: <UilCircle size='10' className='mr-05' />,
        navLink: '/admin/blog/posts'
      },
      {
        id: 'add-post',
        title: 'Añadir post',
        icon: <UilCircle size='10' className='mr-05' />,
        navLink: '/admin/blog/add'
      }
    ]
  },
  {
    id: 'categorias',
    title: 'Categorías',
    icon: <UilTag size='20' className='mr-05' />,
    navLink: '/admin/categorias'
  },
]
