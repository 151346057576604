import React, { useState, useEffect } from 'react'
import { crudAPI } from '../../utility/services/crudAPI.service'

import portada from '../../assets/images/blog/react-router.jpg'

const RelatedPosts = ({ targetTag, changeSlug }) => {
  const [relatedPosts, setRelatedPosts] = useState()
  //console.log(targetTag)
  useEffect(() => {
    crudAPI.findData('posts/query', targetTag).then((res) => {
      //console.log(res)
      setRelatedPosts(res.posts)
    })
  }, [targetTag])

  return (
    <div className='post__relatedPosts grid'>
      {relatedPosts &&
        relatedPosts.slice(0, 3).map((relatedPost) => (
          <div
            key={relatedPost._id}
            className='relatedPosts__post'
            onClick={() => changeSlug(relatedPost.slug)}
          >
            <div className='relatedPosts__img'>
              <img src={portada} alt='imagen portada' />
            </div>
            <div className='relatedPosts__content'>
              <h4 className='relatedPosts__title'>{relatedPost.title}</h4>
            </div>
          </div>
        ))}
    </div>
  )
}

export default RelatedPosts
