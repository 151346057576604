import React, { useContext } from 'react'

import { BrowserRouter as AppRouter, Switch } from 'react-router-dom'
import { AuthContext } from '../utility/context/auth/AuthContext'
import Login from '../views/login/Login'
import DashboardRoutes from './DashboardRoutes'
import HomeRoutes from './HomeRoutes'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'


const Router = () => {

  const { state } = useContext(AuthContext)

  if (!state.isTokenValidated) return <h3>Cargando...</h3>

  return (
    <AppRouter>
      <Switch>
        <PublicRoute exact path='/login' component={Login} isAuth={state.logged} />
        <PrivateRoute path='/admin' component={DashboardRoutes} isAuth={state.logged} />
        <PublicRoute path='/' component={HomeRoutes} isAuth={state.logged} />
      </Switch>
    </AppRouter>
  )
}

export default Router
