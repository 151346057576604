// EXPORTAR LA QUE QUERAMOS UTILIZAR

const config = {
  crud_node: {
    key: 'crud_node',
    baseURL: 'https://api.danielruiz.dev',
    apiURL: '/api/'
  },
  crud_local: {
    key: 'crud_local',
    /* baseURL: 'http://localhost:3001', */
    baseURL: 'https://danielruiz.dev',
    apiURL: '/api/'
  },
  crud_prod: {
    key: 'crud_prod',
    baseURL: 'https://api.danielruiz.dev',
    apiURL: '/'
  }
}

export default config.crud_node
