import React from 'react'

const ScrollUp = () => {

  const scrollTop = () => {
    document.documentElement.scrollTop = 0
  }

  return (
    <span onClick={scrollTop} className="cursor-pointer scrollup" id="scroll-up">
      <i className="uil uil-arrow-up scrollup__icon"></i>
    </span>
  )
}

export default ScrollUp
