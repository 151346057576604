import React from 'react'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__bg'>
        <div className='footer__container container grid'>
          <div>
            <h1 className='footer__title'>DANIELRUIZ</h1>
            <span className='footer__subtitle'>Front-end developer</span>
          </div>

          <ul className='footer__links'>
            <li>
              <a href='#blog' className='footer__link'>
                Blog
              </a>
            </li>
            <li>
              <a href='#portfolio' className='footer__link'>
                Portfolio
              </a>
            </li>
            <li>
              <a href='#contact' className='footer__link'>
                Contact
              </a>
            </li>
          </ul>

          <div className='footer__socials'>
            <a
              href='https://www.linkedin.com/in/danirupe/'
              className='footer__social'
              rel='noreferrer'
              target='_blank'
            >
              <i className='uil uil-linkedin-alt'></i>
            </a>

            <a
              href='https://github.com/danirupe'
              className='footer__social'
              rel='noreferrer'
              target='_blank'
            >
              <i className='uil uil-github-alt'></i>
            </a>

            <a
              href='https://www.instagram.com/daniruizp95/'
              className='footer__social'
              rel='noreferrer'
              target='_blank'
            >
              <i className='uil uil-instagram'></i>
            </a>
          </div>
        </div>

        <p className='footer__copy'>&#169; Daniel Ruiz. All rights reserved</p>
      </div>
    </footer>
  )
}

export default Footer
