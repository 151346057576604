import React from 'react'
import MainMenu from '../menu/MainMenu'

import { NavLink } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div className='admin-sidebar'>
      <div className='sidebar__header'>
        <NavLink to='/' className='nav__logo'>
          DANIELRUIZ<span>.dev</span>
        </NavLink>
      </div>

      <MainMenu />
    </div>
  )
}

export default Sidebar
